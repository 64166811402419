@media all {
  .page-break {
    display: none;
  }
}

@media print {
  html,
  body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }
}

@media print {
  .page-break {
    display: block;
    page-break-before: always;
  }
}

@page {
  size: auto;
}

.learnerHeaderLabels {
  font-size: 14px;
}

.courseNarrativeBlock {
}

.courseLabel {
  font-size: 18px;
  font-weight: bold;
}

.courseNarrativeText {
}

.timestamp {
  font-weight: bolder;
}

.fieldValue {
  font-weight: bolder;
}

.logo {
  width: 200px;
}
